<template>
  <v-app>
    <v-main>
    <v-container fill-height>
        <v-row align="center" justify="center">
            <v-col class="login-form">
                <v-card outlined>
                       <v-img
                            :src="require('../../assets/trojka.svg')"
                            class="my-3 mx-auto"
                            contain
                            width="50%"
                        />
                    <v-card-title class="letter-space">Zaloguj się</v-card-title>
                    <v-card-subtitle class="letter-space">Po zalogowaniu uzyskasz dostęp do dziennika zawodów</v-card-subtitle>
                    <v-card-text class="pb-0">
                        <v-form v-model="formValid">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        :error-messages="errorMessages"
                                        label="Adres email"
                                        required
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="password"
                                        type="password"
                                        label="Hasło"
                                        required
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-row class="text-right">
                            <v-col cols="12" class="pt-0 px-5">
                                <v-btn color="primary" elevation="0" class="text-none" @click="login">Zaloguj</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
      </v-main>
      <v-footer padless>
        <v-col
          class="text-center letter-space"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Dziennik zawodów</strong>
        </v-col>
      </v-footer>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'AuthLoginPage',
    data: () => ({
        formValid: false,
        email: '',
        password: '',
        emailRules: [
            v => !!v || 'E-mail jest wymagany',
            v => /.+@.+/.test(v) || 'E-mail musi być poprawny',
        ],
        errorMessages: []
    }),
    computed:{
        ...mapGetters('user', [
            'error',
            'loading'
        ]),
    },
    methods:{
        login: function(){
            const payload = {
                email: this.email,
                password: this.password
            };
            this.$store.dispatch("user/login", payload);
        }
    }
}
</script>

<style>
.login-form{
    max-width: 448px;
}

.letter-space{
    letter-spacing: -0.4px !important;
}

</style>